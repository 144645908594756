import * as utils from "../../utils/utils";
import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Avatar,
} from "@material-ui/core";
import PositionIcon from "@material-ui/icons/GpsFixed";
import MoneyIcon from "@material-ui/icons/MonetizationOn";
import React from "react";
import EditProfileDialog from "./EditProfileDialog";

function ProfileDetails(props) {
  const { profileToLoad, profile, updateProfile } = props;
  const balanceMessage =
    parseFloat(profileToLoad.balance) > 0
      ? `You have $${profileToLoad.balance} in credit.`
      : `You owe $${profileToLoad.balance}.`;
  return (
    <>
      <Grid container spacing={3}>
        <Grid
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "30px",
          }}
          item
          xs={12}
          md={1}
        >
          <Avatar
            style={{ width: "50px", height: "50px" }}
            alt={profileToLoad.name}
            src={profileToLoad.picture}
          />
        </Grid>

        <Grid item xs={12} md={9}>
          <List>
            <ListItem>
              <Typography variant="h4">{profileToLoad.name}</Typography>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <MoneyIcon />
              </ListItemIcon>
              <ListItemText
                style={{
                  color: utils.getMoneyColour(profileToLoad.balance),
                }}
                primary={`$${profileToLoad.balance}`}
                secondary={balanceMessage}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <PositionIcon />
              </ListItemIcon>
              <ListItemText primary={profileToLoad.position} />
            </ListItem>
            <ListItem
              style={{
                display:
                  profileToLoad._id === profile._id ? "" : "none",
              }}
            >
              <EditProfileDialog
                profile={profile}
                updateProfile={updateProfile}
              />
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={12} style={{ marginTop: "25px", marginBottom: "30px" }}>
          <Typography variant="subtitle2">About</Typography>

          <Typography variant="body1">{profileToLoad.about}</Typography>
        </Grid>
      </Grid>
    </>
  );
}

export default ProfileDetails;
