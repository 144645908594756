import * as utils from "../../utils/utils";
import * as Sentry from "../Sentry";

let authToken = null;

const baseUrl =
  utils.getApiUrl(process.env.REACT_APP_ENVIRONMENT) +
  "/" +
  process.env.REACT_APP_API_VERSION;

function handleResponse(response) {
  if (response.ok) {
    return response.json();
  } else {
    const error = new Error(
      `${response.status}: ${response.statusText} on endpoint ${response.url}`
    );
    handleError(error);
  }
}

function handleError(error) {
  Sentry.onError(error);
}

export function setToken(token) {
  authToken = token;
}

// Games API
export function getGames() {
  //console.log("Getting games with token: %o", authToken)
  return fetch(`${baseUrl}/games`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  })
    .then(handleResponse)
    .catch(handleError);
}
export function createGame(newGameData) {
  return fetch(`${baseUrl}/games`, {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    body: JSON.stringify(newGameData),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function deleteGame(gameid) {
  return fetch(`${baseUrl}/games/${gameid}`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
    method: "DELETE",
  })
    .then(handleResponse)
    .catch(handleError);
}

export function finaliseGame(gameid, playersOnGame, whoPaidCourt, courtFee) {
  const payload = {
    gameid,
    playersOnGame,
    whoPaidCourt,
    courtFee,
  };
  return fetch(`${baseUrl}/games/finalise`, {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    body: JSON.stringify(payload),
  })
    .then(handleResponse)
    .catch(handleError);
}
export function updateGame(gameData) {
  const { name, date, venue, active } = gameData;
  const payload = {
    name,
    date,
    venue,
    active,
  };
  return fetch(`${baseUrl}/games/${gameData._id}`, {
    method: "PATCH",
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    body: JSON.stringify(payload),
  })
    .then(handleResponse)
    .catch(handleError);
}

//  Players API
export function getPlayers() {
  return fetch(`${baseUrl}/players`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  })
    .then(handleResponse)
    .catch(handleError);
}
export function updatePlayer(playerid, newPlayerState) {
  return fetch(`${baseUrl}/players/${playerid}`, {
    method: "PATCH",
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    body: JSON.stringify(newPlayerState),
  })
    .then(handleResponse)
    .catch(handleError);
}
export function createPlayer(name, email, picture, position, about) {
  const newPlayer = {
    name,
    email,
    picture,
    position,
    about,
    active: 0,
    admin: 0,
  };
  return fetch(`${baseUrl}/players`, {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    body: JSON.stringify(newPlayer),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function activatePlayer(playerid) {
  return fetch(`${baseUrl}/players/activate/${playerid}`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function rejectPlayer(playerid) {
  return fetch(`${baseUrl}/players/reject/${playerid}`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  })
    .then(handleResponse)
    .catch(handleError);
}

// Venues API
export function getVenues() {
  return fetch(`${baseUrl}/venues`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  })
    .then(handleResponse)
    .catch(handleError);
}
export function updateVenue(newVenue) {
  const payload = {
    name: newVenue.name,
    address: newVenue.address,
    logo: newVenue.logo,
    phone: newVenue.phone,
    active: newVenue.active,
  };
  return fetch(`${baseUrl}/venues/${newVenue._id}`, {
    method: "PATCH",
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    body: JSON.stringify(payload),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function createVenue(newVenueState) {
  const payload = {
    name: newVenueState.name,
    address: newVenueState.address,
    logo: newVenueState.logo,
    phone: newVenueState.phone,
  };
  return fetch(`${baseUrl}/venues`, {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    body: JSON.stringify(payload),
  })
    .then(handleResponse)
    .catch(handleError);
}

// Attendance API
export function getAttendance() {
  return fetch(`${baseUrl}/attendance`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  })
    .then(handleResponse)
    .catch(handleError);
}
export function addPlayerToGame(playerid, gameid) {
  return fetch(`${baseUrl}/attendance/add`, {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    body: JSON.stringify({ playerid, gameid }),
  })
    .then(handleResponse)
    .catch(handleError);
}
export function removePlayerFromGame(playerid, gameid) {
  return fetch(`${baseUrl}/attendance/remove`, {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    body: JSON.stringify({ playerid, gameid }),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function removeAllPlayersFromGame(gameid) {
  return fetch(`${baseUrl}/attendance/removeall`, {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    body: JSON.stringify({ gameid }),
  })
    .then(handleResponse)
    .catch(handleError);
}

// Transactions API
export function getTransactions() {
  return fetch(`${baseUrl}/transactions`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function createTransaction(name, amount, player) {
  return fetch(`${baseUrl}/transactions`, {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    body: JSON.stringify({ name, amount, player }),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getPlayerTransactions(playerid) {
  return fetch(`${baseUrl}/transactions/${playerid}`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function spendMoney(playerids, amount, name) {
  const payload = {
    players: playerids,
    amount,
    name,
  };
  return fetch(`${baseUrl}/transactions/spend`, {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    body: JSON.stringify(payload),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function takeMoney(transactionName, amount, player) {
  const payload = {
    name: transactionName,
    amount,
    player,
  };
  return fetch(`${baseUrl}/transactions/take`, {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    body: JSON.stringify(payload),
  })
    .then(handleResponse)
    .catch(handleError);
}

// Expenses API
export function getExpenses() {
  return fetch(`${baseUrl}/expenses`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  })
    .then(handleResponse)
    .catch(handleError);
}

// AppConfig API
export function getAppConfig() {
  return fetch(`${baseUrl}/config`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function updatePaymentDetails(configid, newPaymentDetails) {
  const payload = {
    paymentDetails: newPaymentDetails,
    configid,
  };
  return fetch(`${baseUrl}/config/update`, {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    body: JSON.stringify(payload),
  })
    .then(handleResponse)
    .catch(handleError);
}
