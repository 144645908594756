import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import HomeContainer from "../Home/HomeContainer";
import SignIn from "../SignIn/SignInContainer";
import { useAuth0 } from "../../react-auth0-spa";
import ProfileContainer from "../Profile/ProfileContainer";
import AdminContainer from "../Admin/AdminContainer";
import PlayersContainer from "../Players/PlayersContainer";
import GamesContainer from "./../Games/GamesContainer";
import VenuesContainer from "./../Venues/VenuesContainer";

function Routes() {
  const { isAuthenticated } = useAuth0();
  return (
    <>
      <Switch>
        <Route exact path="/">
          <HomeContainer />
        </Route>
        <Route path="/profile/:profileid" children={<ProfileContainer />} />
        <Route path="/profile">
          <ProfileContainer />
        </Route>
        <Route path="/admin">
          <AdminContainer />
        </Route>
        <Route path="/players">
          <PlayersContainer />
        </Route>
        <Route path="/games">
          <GamesContainer />
        </Route>
        <Route path="/venues">
          <VenuesContainer />
        </Route>
        <Route path="/signin">
          {isAuthenticated ? <Redirect to="/" /> : <SignIn />}
        </Route>
      </Switch>
    </>
  );
}

export default Routes;
